import Link from 'next/link';
import { NextRouter, useRouter } from 'next/router';
import classNames from 'classnames';

import { PaginationData } from '@interfaces';

import styles from './listing-pagination.module.scss';

const getHref = (router: NextRouter, pageNumber: number) => {
	const { query, pathname } = router;
	const queryString = new URLSearchParams({
		...query,
		page: pageNumber.toString(),
	}).toString();

	return `${pathname}?${queryString}`;
};

export const ListingPagination = ({
	pageNumber,
	hasNextPage,
}: PaginationData) => {
	const router = useRouter();

	const previousHref = pageNumber > 1 ? getHref(router, pageNumber - 1) : null;
	const nextHref = hasNextPage ? getHref(router, pageNumber + 1) : null;

	return (
		<nav>
			<ul className={classNames(styles.items, 'list-unstyled')}>
				{previousHref ? (
					<li className={styles.previous}>
						<Link className="button" href={`${previousHref}#filter`} shallow>
							Previous
						</Link>
					</li>
				) : null}
				{nextHref ? (
					<li className={styles.next}>
						<Link className="button" href={`${nextHref}#filter`} shallow>
							Next
						</Link>
					</li>
				) : null}
			</ul>
		</nav>
	);
};
