import Link from 'next/link';
import classNames from 'classnames';

import { formatDateShort } from '@common/utils/date';
import { createEventSlug, getEarliestTime } from '@common/utils/programme';
import { DiceLinkoutOriginPageTypes } from '@common/utils/tracking';
import {
	DiceEvent,
	ListingPreviewHeadingTag,
	ProgrammeLabels,
} from '@interfaces';

import styles from './listing-compact.module.scss';
import { ListingAdditionalMicrodata } from './ListingAdditionalMicrodata';
import { ListingAnchors } from './ListingAnchors';
import { ListingBadgeList } from './ListingBadgeList';

interface ListingCompactProps {
	event: DiceEvent;
	isDateHidden?: boolean;
	programmeLabels?: ProgrammeLabels;
	headingTag?: ListingPreviewHeadingTag;
	showTicketAnchors?: boolean;
	serverNow?: number;
	diceAccessCode?: string;
	isElsewherePresents?: boolean;
}

export const ListingCompact = ({
	event,
	isDateHidden = false,
	programmeLabels,
	headingTag = 'h2',
	showTicketAnchors = false,
	serverNow,
	diceAccessCode,
	isElsewherePresents = false,
}: ListingCompactProps) => {
	const earliestTime = getEarliestTime(event);
	const HeadingTag = headingTag as keyof JSX.IntrinsicElements;
	const href = `/${isElsewherePresents ? 'presents' : 'events'}/${createEventSlug(event)}`;

	const isPast = !!serverNow && serverNow > new Date(event.dateEnd).getTime();

	return (
		<article
			className={classNames(styles['listing-compact'], {
				[styles['listing-compact--has-border']]: !isDateHidden,
				[styles['listing-compact--has-ticket-anchors']]: showTicketAnchors,
			})}
			itemScope
			itemType="https://schema.org/MusicEvent"
		>
			<ListingAdditionalMicrodata
				event={event}
				isWithArtistList
				isWithDescription
				isWithImage
			/>
			<time
				className={classNames(styles['listing-compact__date'], 'font-t4')}
				dateTime={event.date}
				hidden={isDateHidden}
				itemProp="startDate"
			>
				{formatDateShort(event.date)}
			</time>
			<HeadingTag
				className={classNames('font-t3-title', styles['listing-compact__body'])}
			>
				<Link
					className={classNames(styles['listing-compact__anchor'], 'breakout')}
					href={href}
					itemProp="url"
				>
					<span itemProp="name">{event.name}</span>
				</Link>
				<ListingBadgeList
					className={styles['listing-compact__badge-list']}
					event={event}
					isElsewherePresents={isElsewherePresents}
				/>
			</HeadingTag>
			{earliestTime ? (
				<time className={styles['listing-compact__time']}>{earliestTime}</time>
			) : null}
			{showTicketAnchors && programmeLabels ? (
				<div className={styles['listing-compact__anchors']}>
					<ListingAnchors
						diceAccessCode={diceAccessCode}
						diceLinkoutOriginPageType={DiceLinkoutOriginPageTypes.SmartLander}
						event={event}
						isOffSale={isPast}
						transparent
						{...programmeLabels}
					/>
				</div>
			) : null}
		</article>
	);
};
