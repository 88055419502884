import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Svg } from '@components/svg/Svg';

import { CarouselButtonProps, CarouselRefs } from '@interfaces';

import styles from './carousel.module.scss';

interface CarouselProps extends CarouselButtonProps {
	refs: CarouselRefs;
	hasControls?: boolean;
	hasPaginationDots?: boolean;
	children: JSX.Element[];
	className?: string;
	paginationDotsClassName?: string;
	isScrollbarHidden?: boolean;
}

export const Carousel = ({
	refs,
	children,
	hasControls,
	hasPaginationDots,
	paginationDotsClassName,
	scrollTo,
	scrollToIndex,
	isNextButtonDisabled,
	isPreviousButtonDisabled,
	isScrollbarHidden = false,
	className,
}: CarouselProps) => {
	const { scrollerRef, listRef } = refs;
	const [activeIndex, setActiveIndex] = useState(0);

	const handleDotClick = (index: number) => {
		scrollToIndex(index);
	};

	const handlePrevious = () => {
		scrollTo('previousElementSibling');
	};

	const handleNext = () => {
		scrollTo('nextElementSibling');
	};

	const handleScroll = () => {
		if (scrollerRef?.current) {
			const { scrollLeft, clientWidth } = scrollerRef.current;
			const index = Math.round(scrollLeft / clientWidth);
			setActiveIndex(index);
		}
	};

	useEffect(() => {
		const scrollerEl = scrollerRef?.current || null;
		if (scrollerEl) {
			scrollerEl.addEventListener('scroll', handleScroll);

			return () => {
				scrollerEl.removeEventListener('scroll', handleScroll);
			};
		}
	}, []);

	return (
		<div
			className={classNames(className, styles.carousel, {
				[styles['carousel--is-scrollbar-hidden']]: isScrollbarHidden,
			})}
		>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
			<div className={styles.scroller} ref={scrollerRef} tabIndex={0}>
				<ul className={classNames(styles.list, 'list-unstyled')} ref={listRef}>
					{React.Children.map(children, (child: JSX.Element) => {
						return (
							<li className={styles.item} key={child.key}>
								{child}
							</li>
						);
					})}
				</ul>
			</div>
			{hasControls ? (
				<>
					<button
						aria-label="Previous"
						className={styles['previous-button']}
						disabled={isPreviousButtonDisabled}
						onClick={handlePrevious}
						type="button"
					>
						<Svg id="arrow-serial-output" />
					</button>
					<button
						aria-label="Next"
						className={styles['next-button']}
						disabled={isNextButtonDisabled}
						onClick={handleNext}
						type="button"
					>
						<Svg id="arrow-serial-output" />
					</button>
				</>
			) : null}
			{hasPaginationDots ? (
				<div
					className={classNames(
						styles['pagination-dots'],
						paginationDotsClassName,
					)}
				>
					{children.map((child, index) => (
						<button
							aria-label={`Go to slide ${index + 1}`}
							className={classNames(styles['pagination-dot'], {
								[styles.active]: index === activeIndex,
							})}
							key={child.key}
							onClick={() => handleDotClick(index)}
							type="button"
						/>
					))}
				</div>
			) : null}
		</div>
	);
};
