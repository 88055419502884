import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import { Markdown } from '@components/markdown/Markdown';
import { MembershipIllustration } from '@components/membershipIllustration/MembershipIllustration';

import { getMembershipUpsellLinkHref } from '@common/utils/membership';
import { useUser } from '@context/UserContext';
import {
	MembershipRecord,
	MembershipUpsellConfigRecord,
	SupabaseProductRecordWithPrices,
} from '@interfaces';

import styles from './programme-membership-upsell-teaser.module.scss';

interface ProgrammeMembershipUpsellTeaserProps {
	membership?: Pick<MembershipRecord, 'tierLevel'>;
	heading?: string;
	text?: string;
	membershipProducts: SupabaseProductRecordWithPrices[] | null;
	membershipUpsellConfig: MembershipUpsellConfigRecord;
	isInGrid: boolean;
}

export const ProgrammeMembershipUpsellTeaser = ({
	membership,
	heading,
	text,
	membershipProducts,
	membershipUpsellConfig,
	isInGrid,
}: ProgrammeMembershipUpsellTeaserProps) => {
	const { subscription } = useUser();

	if (subscription) {
		return null;
	}

	const linkHref = getMembershipUpsellLinkHref(
		membership,
		membershipUpsellConfig,
		membershipProducts,
	);

	if (!linkHref) {
		return null;
	}

	const linkEl = (
		<Link className={classNames(styles.teaser__link, 'button')} href={linkHref}>
			Become a member
		</Link>
	);

	const teaserMobile = (
		<div className={classNames(styles.teaser, styles['teaser-mobile'])}>
			<div className={styles['teaser-mobile__heading']}>
				<h3
					className={classNames(
						styles['teaser-mobile__heading-text'],
						'font-t5-bold',
					)}
				>
					{heading || membershipUpsellConfig.heading}
				</h3>
				<MembershipIllustration
					className={styles['teaser-mobile__illustration']}
					sizes="30.8vw"
					type="upsell"
				/>
			</div>
			<div className={styles['teaser-mobile__text']}>
				<Markdown
					className="font-t5"
					text={text || membershipUpsellConfig.text}
				/>
			</div>
			{linkEl}
		</div>
	);

	const teaserLg = (
		<div className={classNames(styles.teaser, styles['teaser-lg'])}>
			<MembershipIllustration
				className={styles['teaser-lg__illustration']}
				sizes="30.9vw"
				type="upsell"
			/>
			<div className={styles['teaser-lg__info']}>
				<h3
					className={classNames(styles['teaser-lg__heading'], 'font-t5-bold')}
				>
					{heading || membershipUpsellConfig.heading}
				</h3>
				<div className={styles['teaser-lg__text']}>
					<Markdown
						className="font-t5"
						text={text || membershipUpsellConfig.text}
					/>
				</div>
			</div>
			<div className={styles['teaser-lg__link-wrapper']}>{linkEl}</div>
		</div>
	);

	return (
		<>
			<div
				className={classNames(
					styles['teaser-wrapper'],
					{
						[styles['teaser-wrapper--is-in-grid']]: isInGrid,
					},
					'wave-background-top wave-background-bottom',
				)}
			>
				{teaserMobile}
				{teaserLg}
			</div>
		</>
	);
};
