import { CSSProperties } from 'react';
import classNames from 'classnames';

import { convertFocalPointToObjectPosition } from '@common/utils/image';
import { ImageSectionRecord } from '@interfaces';

import styles from './image-section.module.scss';

type ImageSectionProps = ImageSectionRecord;

export const ImageSection = ({ imageOne, imageTwo }: ImageSectionProps) => {
	return (
		<div
			className={classNames(
				styles['image-section'],
				!imageTwo && styles['image-section--single-image'],
			)}
		>
			{[imageOne, imageTwo].map(
				(image) =>
					image && (
						<div className={styles['image-section__image-container']}>
							<img
								alt={image.alt || ''}
								className={classNames(
									styles['image-section__image'],
									image.focalPoint &&
										styles['image-section__image--focal-point'],
								)}
								height={image.height}
								loading="lazy"
								sizes={
									imageTwo
										? '(min-width: 1520px) 624px, (min-width: 56.25em) calc(50vw - 6rem), calc(100vw - 8rem)'
										: '(min-width: 1520px) 1312px, (min-width: 56.25em) calc(100vw - 8rem), calc(100vw - 2rem)'
								}
								src={image.responsiveImage.src}
								srcSet={image.responsiveImage.srcSet}
								style={
									image.focalPoint &&
									({
										'--focal-point-x': `${convertFocalPointToObjectPosition(
											image.focalPoint.x,
										)}%`,
										'--focal-point-y': `${convertFocalPointToObjectPosition(
											image.focalPoint.y,
										)}%`,
									} as CSSProperties)
								}
								width={image.width}
							/>
						</div>
					),
			)}
		</div>
	);
};
