import classNames from 'classnames';

import { SpacePreview } from '@components/spacePreview/SpacePreview';

import { SpaceListRecord } from '@interfaces';

import styles from './space-list.module.scss';

interface SpaceListProps extends SpaceListRecord {
	className?: string;
	isHeadingVisible?: boolean;
	isCompact?: boolean;
	previewHeadingTag?: 'h2' | 'h3';
}

export const SpaceList = ({
	className,
	isHeadingVisible = true,
	isCompact = false,
	isRentals,
	spaces,
	title,
	previewHeadingTag,
}: SpaceListProps) => {
	return (
		<div
			className={classNames(className, styles['space-list'], {
				[styles['space-list--is-compact']]: isCompact,
			})}
		>
			{isHeadingVisible ? (
				<h2 className={styles['space-list__heading']}>{title}</h2>
			) : null}
			<div className={styles['space-list__previews']}>
				{spaces.map((space) => {
					const sizes = isCompact
						? '(min-width: 56.25em) 33.3333vw, 100vw'
						: '(min-width: 56.25em) 52vw, 100vw';

					return (
						<SpacePreview
							className={styles['space-list__preview']}
							headingTag={previewHeadingTag}
							isCompact={isCompact}
							isRentals={isRentals}
							isResponsive={!isCompact}
							key={space.id}
							sizes={sizes}
							{...space}
						/>
					);
				})}
			</div>
		</div>
	);
};
