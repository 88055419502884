import { CSSProperties, useRef } from 'react';

import { Carousel } from '@components/carousel/Carousel';
import { Markdown } from '@components/markdown/Markdown';
import { SplitHeading } from '@components/splitHeading/SplitHeading';

import { useCarousel } from '@hooks/useCarousel';
import { CarouselSectionRecord } from '@interfaces';

import styles from './carousel-section.module.scss';

type CarouselSectionProps = CarouselSectionRecord;

export const CarouselSection = ({
	title,
	images,
	primaryText,
	secondaryText,
}: CarouselSectionProps) => {
	const scrollerRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<HTMLUListElement>(null);

	const carouselRefs = { scrollerRef, listRef };

	const carouselButtonProps = useCarousel(carouselRefs);

	return (
		<div className={styles['carousel-section']}>
			{title ? (
				<SplitHeading
					className={styles['carousel-section__heading']}
					heading={title}
					tag="h2"
				/>
			) : null}
			{primaryText ? (
				<Markdown
					className={styles['carousel-section__primary-text']}
					text={primaryText}
				/>
			) : null}
			<Carousel
				className={styles['carousel-section__carousel']}
				hasControls
				refs={carouselRefs}
				{...carouselButtonProps}
			>
				{images.map((image) => {
					const { width, height } = image;
					const scaleFactor = width / height < 1 ? width / height : 1;

					const style = {
						'--image-scale-factor': scaleFactor,
					} as CSSProperties;

					const sizes = `(min-width: 56.25em) ${48 * scaleFactor}vw, ${
						100 * scaleFactor
					}vw`;

					return (
						<figure
							className={styles['carousel-section__figure']}
							key={image.id}
							style={scaleFactor === 1 ? undefined : style}
						>
							<img
								alt={image.alt || ''}
								className={styles['carousel-section__image']}
								height={image.height}
								loading="lazy"
								sizes={sizes}
								src={image.responsiveImage.src}
								srcSet={image.responsiveImage.srcSet}
								width={image.width}
							/>
							{image.title ? (
								<figcaption className={styles['carousel-section__figcaption']}>
									{image.title}
								</figcaption>
							) : null}
						</figure>
					);
				})}
			</Carousel>
			{secondaryText ? (
				<Markdown
					className={styles['carousel-section__secondary-text']}
					text={secondaryText}
				/>
			) : null}
		</div>
	);
};
