import Link from 'next/link';
import classNames from 'classnames';

import { Badge } from '@components/badge/Badge';
import { ClippedImage } from '@components/clippedImage/ClippedImage';
import { Markdown } from '@components/markdown/Markdown';

import { SPACES_URL } from '@data/constants';
import { SpacePreviewData } from '@interfaces';

import styles from './space-preview.module.scss';

interface SpacePreviewProps extends SpacePreviewData {
	isRentals?: boolean;
	isResponsive?: boolean;
	isCompact?: boolean;
	sizes?: string;
	className?: string;
	headingTag?: 'h2' | 'h3';
}

export const SpacePreview = ({
	id,
	slug,
	title,
	rentalsPreviewImage,
	previewImage,
	rentalsPreviewDescription,
	previewDescription,
	previewLinkLabel,
	isRentals = false,
	isResponsive = true,
	isCompact = false,
	sizes = '100vw',
	className,
	headingTag = 'h3',
}: SpacePreviewProps) => {
	const text = isRentals ? rentalsPreviewDescription : previewDescription;
	const clippedImage =
		isRentals && rentalsPreviewImage ? rentalsPreviewImage : previewImage;
	const isLinkVisible = previewLinkLabel && !isRentals;
	const HeadingTag = headingTag as keyof JSX.IntrinsicElements;
	const spaceUrl = `${SPACES_URL}/${slug}`;

	return (
		<article
			className={classNames(className, styles['space-preview'], {
				[styles['space-preview--is-responsive']]: isResponsive,
			})}
			key={id}
		>
			<ClippedImage
				className={styles['space-preview__image']}
				{...clippedImage}
				sizes={sizes}
			/>
			<div className={styles['space-preview__body']}>
				<HeadingTag className={styles['space-preview__heading']}>
					{isCompact ? (
						<Link className="breakout" href={spaceUrl}>
							<Badge>{title}</Badge>
						</Link>
					) : (
						<Badge>{title}</Badge>
					)}
				</HeadingTag>
				{!isCompact ? (
					<>
						<Markdown className={styles['space-preview__text']} text={text} />
						{isLinkVisible ? (
							<Link
								className={classNames(styles['space-preview__link'], 'anchor')}
								href={spaceUrl}
							>
								{previewLinkLabel}
							</Link>
						) : null}
					</>
				) : null}
			</div>
		</article>
	);
};
